import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`1st Day of Test Week`}</em></p>
    <p>{`Front Squats 1-1-1-1-1 to a 1RM`}</p>
    <p>{`Thruster 1-1-1-1-1 to a 1RM`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p">{`“Elizabeth”`}</strong></p>
    <p>{`21-15-9 reps each of:`}</p>
    <p>{`Full Clean (135/95)`}</p>
    <p>{`Ring Dips`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday, June 2nd is our monthly free workout at 9:00 &
10:00am so bring a friend!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Sunday, June 3rd we will have a Mobility for Athletic Performance
workshop from 1:45-3:00pm.  This workshop will be taught by Stephanie
Bellissimo and will focus on floor work and locomotion for CrossFit
athletes.  This intro class is free to all CrossFit the Ville members
and just \\$15 for non members.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      